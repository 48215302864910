.filter_container button {
  border-radius: 25px !important;
  color: #47b475 !important;
  line-height: 0px;
  font-weight: 500 !important;
  padding: 0 16px !important;
  font-size: 11px !important;
}
.filter_container button:hover {
  color: #fff !important;
}
