.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.not-subscribed {
  text-align: center;
  min-height: 100px;
}


.printable th,
.printable td {
  font-size: 8px!important;
  text-align: left;
}

.printable th {
  font-weight: 400;
}
.printable address {
  font-size: 8px;
  font-weight: 400;
}
.printable .print-pdf center table tr th,.printable .print-pdf center table tr td{
width: auto!important;
max-width: unset!important;
min-width: unset!important;
line-height: initial!important;
}
.printable .print-pdf center table tr th{
  border-bottom: none!important;
}
.printable * {
  border-color: #dcdcdc!important;
}
.header-printable *{
  border: none!important;

}
.printable table tr th:first-child{
  width: 150px!important;
}
.border-top-0{
  border-top:0!important;
}
.border-left-0{
  border-left:0!important;
}
.border-right-0{
  border-right:0!important;
}
.border-bottom-0{
  border-bottom:0!important;
}

/* .printable .table {
  border-width: 1px !important;
  border-collapse: collapse!important;
} */

.cancel-confirm-subscription h1 {
  font-size: 38px;
  color:#ffffff;
  line-height: 38px;
}


.cancel-confirm-subscription {
  background: rgb(71, 180, 117);
  text-align: center;
  /* width: 500px; */
  padding: 40px;
  box-shadow: 0 20px 75px rgb(0 0 0 / 23%);
  color: #fff;
}


.cancel-confirm-subscription button {
  width: 160px;
  padding: 10px;
  border: 1px solid #fff;
  margin: 10px;
  cursor: pointer;
  background: none;
  color: #fff;
  font-size: 14px;
}