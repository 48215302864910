.plan .card {
    border: none;
    border-radius: 1rem;
    transition: all 0.2s;
    /* box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1); */
}

.plan .card-body{
    padding: 1.25rem;
}

.plan .card-title {
    margin: 0.5rem 0 2rem;
    font-size: 2rem;
    letter-spacing: .1rem;
    font-weight: bold;
}

.plan .card-price {
    font-size: 3rem;
    margin: 0;
}

.plan .card-price .period {
    font-size: 1.5rem;
}

.plan hr {
    margin: 1.5rem 0;
}

.plan a.btn {
    color: #fff;
}


.plan-detail .form-control{
    background: #f5f5f5 !important;;
    border: none !important;
    width: 100% !important;;
    height: 50px !important;;
    padding-left: 20px !important;;
    font-weight: 500 !important;;
    margin-bottom: 24px !important;;
    border-radius: 0 !important;;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
    -moz-appearance: textfield;
}

.plan-detail .btn{
   color: #fff !important;
}



.transition tr th:first-child {
    width: auto !important;   
}

.transition th, td{
    border: 1px solid #ddd;
    font-size: 15px; 
}
.table-hover td{
    padding: 6px 15px;
}

table.table-responsive-sm.table-hover {
    width: 100%;
}

.btn.text-black{
    color: #fff !important;
}

.add-img {
    width: 200px;
    margin: 70px auto 40px;
    opacity: .4;
}

.header-tabs > li.active > a, .header-tabs > li.active > a:hover, .header-tabs > li.active > a:focus {
    border: 1px solid transparent  !important;
    border-bottom-color: #47b475  !important;
}

.order-table tr th:first-child {
   width: auto !important
}

.header-tabs {
    margin-bottom: 30px !important;
}

.order-table thead {
    border: 1px solid#dee2e6;
}
caption.caption-title {
    font-size: 16px;
}

.order-table th {
    font-size: 16px;
}

.address-detail {
    border: 1px solid #ddd;
    padding: 20px 10px;
    margin-bottom: 20px;
}

h5.title-name {
    margin-bottom: 10px;
}

address {
    font-size: 15px;
}

.address-detail p {
    margin-bottom: 10px;
    font-size: 15px;
}

h4.order-title {
    margin-bottom: 10px;
    margin-top: 30px;
}
ul.card-imges {
    margin-bottom: 10px;
}
ul.card-imges li {
    display: inline-block;

}
.circle-dot {
    border-radius: 50%;
    height: 28px; display: inline-block;
    background:  #455A64;
    width: 28px;
    margin-right:15px;
    position: relative;
    text-align: center;
}

svg.feather.feather-edit-2 {
    color: #fff;
    width: 15px;
    height: 27px;
    
}

.subscription li.active .circle-dot{
    background: #47b475;
}

.subscription li.active a{
    color: #47b475;
}
ul.subscription li a {
    padding: 10px;
    border-radius: 4px;
}

ul.subscription li a:hover {
    background: #f1eeee;
}

.subscription li.active a{
    background: #f1eeee;
}
.subscription li.active.current-active a{
    background: #ffffff;
}


.subscription > li > a {
    display: flex !important;
    align-items: center;
}

.subscription{
    border:none !important;
    display: flex;
    overflow: auto;
    justify-content: space-between;
    align-items: center;
}
.horizontal-line{
    border-top-width: 1px;
    border-top-style: solid;
    flex: auto;
    border-color: #ddd;
    height: 0;
    min-width: 32px;
}

.subscription .d-flex {
    width: 100%; white-space: nowrap;
    display: flex !important;
    align-items: center;
}

.h-line {
    flex: auto;
}

a.mr-10 {
    margin-right: 10px;
}

.tab-content ::-webkit-scrollbar {
    display: none;
} 

.tab-content .table-responsive {
    overflow-x: inherit;
}
.alert-soft-danger{
    color: #721c24;
    background-color: #f8d7da !important;
    border-color: #f5c6cb
}
