@import url("https://pro.fontawesome.com/releases/v5.10.0/css/all.css");
.left_section {
    width: 350px;
    height: 100%;
    box-shadow: 3px 0px 13px #faf7f7;
    background-color: #444653;
    transition: all 0.5s linear;
}
.right_section {
    width: calc(100% - 350px);
        transition: all 0.5s linear;
}
.conatiner_wrapper {
    overflow: hidden;
}
.h_100{
    height: 100vh;
}
.focus_out:focus{
    outline: none;
}
.bg_off{
    background-color: #de8868
}
.bg_onn{
    background-color: #87ba75
}
.bg_next{
    background-color: #BBDEFB;
}
.bg_pre{
    background-color: #eff1f0;
}
.border_bottom {
    border-bottom:1px solid #eeeeee;
}
.mr_10{
    margin-right: 6px;
}
.ml_10{
    margin-left: 6px;
}
.chat_scroll_auto{
    height: 100%;
    min-height: calc(100% - 171px);
    
    scroll-behavior: smooth;
}
.real_position{
    position: relative;
}
.abs_position{
    position: absolute;
}
.file_upload {
    width: 30px;
    overflow: hidden;
    display: flex;
    height: 100%;
    margin-right: 8px;
    margin-top: 3px;
}
.file_upload input {
    z-index: 1;
    position: relative;
    opacity: 0;
    cursor: pointer;
}
.file_upload i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    cursor: pointer;
    font-weight: 300;
    font-size: 20px;
}
/* Left_section */

.p_16{
    padding: 16px;
}
.search_box {
    padding: 16px 10px;
    height: 73px;
}
.search_style {
    background-color: #fff;
    padding: 10px;
    border-radius: 30px;
}
.flex_center{
    align-items: center;
}
.search_input {
    background-color: transparent;
    border: 0;
    width: 100%;
    font-size: 14px;
    font-weight: 300;
    padding: 0 10px;
}
.search_style  > i{
    font-weight: 300;
    font-size: 14px;
}
.cs_drop.top_cs_drop > div{
    background-color: #444653;
    width: 5px;
    height: 5px;
}
.flex{
    display: flex;
}
.ml_auto{
        margin-left: auto;
}
.block_box > h2 {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 0;
    color: #caccce;
}
.sm_icon_show{
    display: none;
}


.offline_text{
    color: #caccce;
}
.block_box > h5{
    font-size: 13px;
    text-transform: capitalize;
    font-weight: 400;
    margin-bottom: 2px;
}
.bg_age {
    display: inline-flex;
    background-color: #dc3545;
    font-size: 11px;
    font-weight: 600;
    border-radius: 50%;
    padding-right: 1px;
    width: 17px;
    height: 17px;
    align-items: center;
    justify-content: center;
    color: #ede7e7;
    margin-left: 3px;
}
.loop_box {
    /* margin-bottom: 30px; */
    padding-top: 15px;
    padding-bottom: 15px;
}

.loop_box:last-child {
    margin-bottom: 0;
}
.u_info{
    width: calc(100% - 45px);
    white-space: nowrap;
}
.u_img {
    width: 45px;
    height: 45px;
    margin-right: 10px;
    position: relative;
}
.u_img > img{
    width: 100%;
    height: 100%;
}
.u_info > h5 {
    font-size: 14px;
    text-transform: capitalize;
    font-weight: 400;
    margin-bottom: 2px;
}
.u_info > p {
    font-size: 13px;
    margin: 0;
    text-transform: capitalize;
}
.u_info > p > span {
    display: inline-block;
    width: 7px;
    height: 7px;
    border-radius: 50%;
}
.scroll_auto{
    min-height: calc(100% - 143px);
    overflow: auto;
    color: #fff;
}
.arrow_section {
    border-top: 1px solid #3d3d3d;
    padding: 16px;
}
.arrow_section > i {
    width: 38px;
    height: 38px;
    background-color: #3a3a3d;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 12px;
    font-weight: 200;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.80s linear;
}
.footer_left{
    height: 70px;
}
/* Left_section End*/

/* Right_section */
    .w_80 {
        width: calc(100% - 20%);
    }
    .end_item {
        justify-content: flex-end;
    }
    .top_header{
        padding: 10px;
    }
    .chat_show_container {
        height: 100%;
        flex-grow: 1;
        background-color: #f1f5f9;
    }
    .flex_col{
        flex-direction: column;
    }
    .w_100{
        width: calc(100% - 53px);
    }
    .u_info h3 {
        font-size: 16px;
        margin-bottom: 0px;
        font-weight: 600;
    }
    .box {
        margin-bottom: 16px;
    }
    .chat_head {
        margin-bottom: 3px;
    }
    .chat_user_icon {
        width: 40px;
        height: 40px;
        overflow: hidden;
    }
    .chat_user_icon > img{
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }
    .chat_head p{
        margin-bottom: 0;
        font-size: 13px;
        text-transform: capitalize;
    }
    .time{
        color: #4e4e4e;
        font-weight: 400;
        margin-bottom: 0;
        font-size: 9px;
        line-height: 19px;
        text-align: right;
    }
    .seen_text {
        color: #7d7d7d;
        margin-bottom: 0;
        width: 100%;
        display: block;
        text-align: right;
        text-transform: capitalize;
        letter-spacing: 0.4px;
        font-weight: 400;
        font-size: 9px;
    }
    .arrow_left::before {
        content: '';
        position: absolute;
        top: 0;
        left: -12px;
        width: 0;
        height: 0;
        border-top: 1px solid transparent;
        border-right: 18px solid #eff1f0;
        border-bottom: 15px solid transparent;
    }

.title_text {
    margin: 0;
    font-size: 13px;
    font-weight: 400;
    line-height: 21px;
}
.inner_chat_info{    
    position: relative;
    padding: 2px 6px;
    border-radius: 5px;
    display: inline-block;
}
.order_2 {
    order: 2;
}
.order_1 {
    order: 1;
}
.lr_user .arrow_right::before {
    content: '';
    position: absolute;
    top: 0;
    right: -12px;
    width: 0;
    height: 0;
    border-top: 1px solid transparent;
    border-left: 18px solid #BBDEFB;
    border-bottom: 15px solid transparent;
}
.chat_container_wp{
    border: 1px solid #eaeaea;
    background-color: #fff;
    border-radius: 30px;
}
.chat_input_section textarea {
    width: 100%;
    resize: none;
    border:0px;
    padding: 10px;
    border-radius: 30px;
}
.cs_button {
    background-color: #444653;
    border: 0;
    padding: 10px 22px;
    font-size: 16px;
    color: #fff;
    border-radius: 3px;
    margin-left: 10px;
    border-radius: 40px;
}
.chat_input_section {
    padding: 25px 30px;
    background-color: #f1f5f9;
}
.top_header > .chat_user_icon {
    margin-right: 16px;
}
.cs_drop {
    padding: 0;
    color: #d5cdcd;
    font-size: 16px;
    font-weight: 300;
}
.cs_drop:focus{
    box-shadow: none;
}
.cs_drop:hover{
    color: #d5cdcd;
}
.cs_drop_title a {
    font-size: 13px;
}
.cs_drop > div {
    width: 3px;
    height: 3px;
    background-color: #7d7d7d;
    margin-bottom: 2px;
    border-radius: 50%;
}
.cs_drop > div:last-child {
    margin-bottom: 0px;
}
.img_block {
    width: 150px;
    height: 150px;
    object-fit: cover;
}
.img_block > img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}
.right_text{
    text-align: right;
}
.right_text .time{
    text-align: left;
}
.right_text .seen_text{
    text-align: left;
}
.w_img{
    max-width: 100%;
}
.table_con{
    width: 100%;
    resize: none;
    border: 0px;
    padding: 10px;
    border-radius: 30px;
    max-height: 42px;
    overflow-x: hidden;
    overflow-y: auto;
}
.table_con:focus{
    outline: none;
}
/* Right_section End */

/* Event Class*/
    .side_bar_hide :is(.u_info,.search_style) {
        display: none;
    }
    .side_bar_hide .u_img{
        margin-right: 0px;
    }
    .side_bar_hide {
        width: 80px;
        transition: all 0.5s linear;
    }
    .main_container_full_width {
        width: calc(100% - 80px);
        transition: all 0.5s linear;
    }
    .btn_animate{
        transform:rotate(180deg);
        transition: 0.80s linear;
    }
    .side_bar_hide .user_status {
        display: inline-block;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        opacity: 1;
        position: absolute;
        bottom: 0;
        left: 100%;
    }
    .user_status {
        opacity: 0;
    }
    .side_bar_hide .side_active {
        padding: 16px 0px;
    }


	
    .close_icon {
        width: 16px;
    }
    .title_model_header {
        margin: 0;
        font-size: 17px;
        text-transform: capitalize;
        font-weight: 500;
    }
    .btn_close {
        background-color: transparent;
        border: 0;
        margin: 0;
        padding: 0;
        margin-left: auto;
    }
    .focus_out:focus {
        outline: none;
        box-shadow: none;
    }
    .drop_box{
        padding: 5rem 1rem;
        background-color: #fff;
        border: 1px dashed #d2ddec;
        border-radius: .375rem;
        text-align: center;
        color: #95aac9;
        transition: all .2s ease-in-out;
        order: -1;
        cursor: pointer;
        z-index: 999;
        width: 100%;
        font-size: 13px;
        letter-spacing: 0.5px;
    }
    .upload_file {
        position: absolute;
        top: 0;
        display: none;
    }
    .drop_box button {
        background-color: transparent;
        border: 0;
        font-size: 13px;
        height: 100%;
        width: 100%;
        color: #95aac9;
    }
    .up_icon {
        width: 50px;
    }
    .file_info_text h2, h3 {
        font-size: 12px;
        text-transform: capitalize;
        letter-spacing: 0.6px;
        margin-bottom: 2px;
        font-weight: 800;
    }
    .file_info_text h3 {
        font-size: 11px;
    }
    .btn_save_cs {
        background-color: #444653;
        color: #fff;
        font-size: 13px;
        border-radius: 50px;
        text-transform: capitalize;
        letter-spacing: 0.4px;
        font-weight: 300;
        padding: 7px 9px;
    }
    .btn_save_cs:hover {
        color: #fff;
    }
    
    .hr_as h2 {
        margin: 0;
        text-transform: capitalize;
        font-family: sans-serif;
        font-size: 13px;
        width: 100%;
        text-align: center;
        position: relative;
    }
    
    .hr_as h2:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 0px;
        height: 2px;
        background: #000;
        width: 48%;
        z-index: -1;
    }
    .hr_as h2:before {
        content: '';
        position: absolute;
        top: 50%;
        right: 0px;
        height: 2px;
        background: #000;
        width: 48%;
        z-index: -1;
    }
    

    scroll_bar::-webkit-scrollbar {
        width: 10px;
      }
    
      .scroll_bar::-webkit-scrollbar-thumb {
        background: #707982;
        border-radius: 10px;
      }



/*  File CSS Section */



.border_clr {
    border:1px solid #e2e3e5;
}
.card > .card_body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.5rem;
}
.card_img {
    display: inline-block;
    width: 40px;
    height: 40px;
}
.card_img > img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: .375rem!important;
}
.text_size {
    font-size: 14px;
}

/* Event Section*/


/* emoji */
.emoji_section {
    position: absolute;
    top: 0;
    width: 100%;
    right: 0;
    transform: translate(0%,-100%);
}

.emoji_section_hide{
    
    display: none;
}

aside.emoji-picker-react {
    height: 320px;
    width: 526px;
    margin-left: auto;
}

/* end */
/* Media Query  */

@media (max-width:425px){
    .w_80{
        width: calc(100% - 10px);
    }
}

@media (max-width:595px){
    .right_section {
        width: calc(100%);
    } 
    .sm_left_section {
        position: absolute;
        z-index: 1;
        width: 100%;
        transition: all 0.5s linear;
        left: 0;
    }
    .side_bar_hide{
        left: -100%;
        transition: all 0.5s linear;
    }
    .sm_icon_show{
        display: block;
    }

}

.d-flex {
    display: -ms-flexbox!important;
    display: flex!important;
}
.right_section .btn {
    min-width: 0;
    outline: none;
    border: 0;
}
.btn.cs_drop.top_cs_drop {display: flex;flex-flow: column;justify-content: center;}

.cs_drop_title a {
    padding: 0 15px;
    cursor: pointer;
}

.dropdown-menu.cs_drop_title {
    left: unset;
    right: 0;
}

.right_section  .btn:hover {
    background: inherit;
}

.h_100.cu_78 {
    max-height: calc(100vh - 56px);
}

.left_section.side_bar_hide {
    justify-content: space-between;
}
.left_section .block_box > h2 {
    line-height: inherit;
}

.table_con.mb-0 {
    margin-bottom: 0;
    border: 0!important;
    outline: unset!important;
    background-color: transparent;
}
.cs_button.focus_out {
    line-height: inherit;
    border-radius: 24px!important;
    overflow: hidden;
}
.chat_container_wp .fa-grin-squint {
    font-size: 20px!important;
    margin-right: 16px;
}
.px_16{
    padding-left: 16px;
    padding-right: 16px;
}
.py_16{
    padding-top: 16px;
    padding-bottom: 16px;
}

.py_4{
    padding-top: 4px;
    padding-bottom: 4px;
}
.loop_box:hover {
    background-color: #4e505e;
}

.welcome-chat{
    padding-top: 45vh;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
}

.chat_text.right_text {
    margin-top: 20px;
    margin-right: 12px;
}

.chat_text.left_text {
    margin-top: 20px;
    margin-left: 12px;
}
.emoji-picker-react .emoji-scroll-wrapper {
    padding: 0;
}
.emoji-fade {
    /* background: red; */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}