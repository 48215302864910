.docHub_schedule_table_box {
  padding-top: 3em;
}

.docHubcalendar_container {
  display: flex;
  flex-wrap: nowrap;
  height: auto;
  width: 100%;
  padding: 10px;
  /* background: red; */
  flex-direction: row;
  justify-content: space-between;
}

.docHubcalendar_container table {
  width: 100%;
}

#dochubweek_left_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 5%;
  cursor: pointer;
}

#docHubweekend_box {
  display: flex;
  height: auto;
  width: 90%;
  /* background: yellow; */
}

#dochubweek_right_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 5%;
  cursor: pointer;
}

.docHubcalendar_container tbody {
  display: inline-block;
  flex-wrap: wrap;
  margin: 0px;
  width: 100%;
}

.docHubcalendar_container tr {
  display: flex;
  margin: 0px;
  width: 100%;
  /* background: gray; */
  justify-content: space-around;
}

.docHubcalendar_container th {
  margin: 5px;
  padding: 10px;
  font-weight: bold;
  display: inline-flex;
}

.docHubcalendar_container tr td {
  display: flex;
  margin: 5px;
  width: 100%;
  padding: 10px;
  /* background: gray; */
  justify-content: space-around;
  font-weight: bold;
}

.consult_schedule_webview_container {
  display: flex;
  flex-wrap: nowrap;
  height: auto;
  width: 100%;
  padding: 10px;
  /* background: red; */
  flex-direction: row;
  justify-content: space-between;
}

/*-------------------------------for-mobile----------------------------------*/

.consult_schedule_mobile_container {
  height: auto;
  width: 100%;
  /* background: red; */
  display: none;
}

.consult_schedule_mobile_view {
  margin-bottom: 0px;
  width: 100% !important;
}

.consult_schedule_mobile_view_title {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.cunsult_schedule_btn_box {
  display: flex;
  flex-wrap: wrap;
  height: auto;
  width: 100%;
  /* background: red; */
  padding-top: 15px;
  padding-bottom: 15px;
  justify-content: center;
}
.cunsult_schedule_btn_box_new {
  display: flex;
  flex-wrap: wrap;
  height: auto;
  width: 100%;
  /* background: red; */
  padding-top: 15px;
  padding-bottom: 15px;
  justify-content: center;
}

.cunsult_schedule_btn_box button {
  height: 30px;
  font-size: 11px;
  border-radius: 25px !important;
  color: #47b475 !important;
  line-height: 0px !important;
  margin-bottom: 8px !important;
}

.cunsult_schedule_btn_box_new button {
  height: 30px;
  font-size: 11px;
  border-radius: 25px !important;
  color: #47b475;
  line-height: 0px !important;
  margin-bottom: 8px !important;
}

.cunsult_schedule_btn_box button:hover {
  color: #fff !important;
}

.consult_accordion_action_box {
  display: flex;
  height: auto;
  width: 100%;
  /* background: red; */
  padding: 13px;
  justify-content: space-between;
}

.consult_accordion_action_box button {
  font-weight: bold;
  cursor: pointer;
  color: #fff;
  line-height: 0px !important;
}

.doctor_intro_box select {
  margin-bottom: 10px;
  margin-top: 5px;
  float: none;
  background-color: #eee;
  width: 30%;
  font-size: 13px;
  text-align-last: center
}

/*----------------------------------for-media-query----------------------------------------*/

@media (max-width: 768px) {
  .consult_schedule_mobile_container {
    display: block !important;
  }
  .consult_schedule_webview_container {
    display: none;
  }
  .go_back_btn {
    width: 100%;
    margin-bottom: 20px;
  }
  .doctor_intro_box select {
    width: 100%;
  }
  .container .feature.bordered {
    border: none !important;
  }
}