@media (max-width: 768px) {
  .cardrightContent .ml16 {
    padding-left: 0px !important;
  }
  .cardrightContent {
    padding-top: 10px;
    width: 100% !important;
  }
  .card_action_box .btn {
    min-width: 0px !important;
  }
}

@media (max-width: 359px) {
  .cardrightContent .ml16 {
    padding-left: 0px !important;
  }
  .cardrightContent {
    padding-top: 10px;
    width: 100% !important;
  }
  .card_action_box {
    display: block !important;
  }
  .card_action_box .btn {
    min-width: 0px !important;
  }
}

.card_action_box {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.blackColor{
  color: #000 !important;
}