.profile_container {
  position: relative !important;
  height: 150px !important;
  width: 150px !important;
  background-size: cover !important;
  background-position: center !important;
  border-radius: 50% !important;
  z-index: 100;
}
.profile_after_container {
  content: "";
  height: 100%;
  width: 100%;
  background: #333;
  position: absolute;
  border-radius: 50%;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  opacity: 0.8;
  cursor: pointer;
  display: none;
}

.profile_container:hover .profile_after_container {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.profile_action_container {
  height: auto;
  width: 150px;
  position: absolute;
  border-radius: 5px;
  background: gray;
  top: 175px;
  z-index: 100;
  display: block;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  left: 45%;
}

.profile_action_container::after {
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  top: -12px;
  z-index: -1;
  border-bottom: solid 50px gray;
  border-left: solid 50px transparent;
  border-right: solid 50px transparent;
}

.profile_action_container ul li {
  width: 100%;
  list-style: none;
  padding: 5px 0px 0px 0px;
  color: lightgray;
  font-weight: bold;
  cursor: pointer;
}

.profile_action_container ul li:hover {
  color: #fff;
}

.profile_backdrop {
  height: 100%;
  width: 100%;
  background: transparent;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 90;
  display: none;
}

#multiple_box {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px 0px 10px 0px;
}

#added_items_box {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: auto;
  width: auto;
  border-radius: 20px;
  padding: 5px 5px 5px 15px;
  background: rgb(71, 180, 117);
  color: #fff;
  font-weight: bold;
  align-items: center;
  margin: 5px;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}

.zip_search_container {
  height: auto;
  width: 100%;
  background-color: #fff;
  padding: 5px 0px 5px 0px;
  position: absolute;
  top: 48px;
  z-index: 100;
  border-radius: 3px;
  border: 1px solid lightgray;
}

.zip_items {
  display: flex;
  height: auto;
  width: 100%;
  padding: 10px;
  cursor: pointer;
  margin: 2px;
}
.zip_items span {
  line-height: 35px;
  margin-left: 10px;
  cursor: pointer !important;
}
.zip_items:hover {
  background-color: #f0f0f5;
}

/* dropdown  */

.pos_change {
  position: absolute;
  z-index: 99999;
  border: 0px solid;
  width: 100%;  
}
.effect_bottom[aria-expanded="true"] ~ .pos_change {
  box-shadow: 0px 6px 24px -20px #000
}
.pos_change ~ .col-md-6[name="practice_location"] {
  margin-right: 4px !important;
}

/* End */
