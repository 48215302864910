        .video-grid {
            display: grid;
            grid-template-columns: repeat(3,33.33%);
            border-radius: 5px;
            gap: 10px;
        }
       .video-box{
            margin-bottom: 1.5rem;
            background: #fff;
        }
        .video-box > .v-video {
            width: 100%;
            height: 300px;
            border-radius: 5px;
            box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 10%);
        }
        @media (max-width:992px){
            .video-grid {
                display: grid;
                grid-template-columns: repeat(2,50%);
                border-radius: 5px;
                gap: 10px;
            }
        }
         @media (max-width:576px){
            .video-grid {
                display: grid;
                grid-template-columns: repeat(1,100%);
                border-radius: 5px;
                gap: 10px;
            }
        }