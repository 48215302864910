/*---------------------------calendar-global_style----------------------------*/
.dateCalender .react-date-picker__inputGroup__input.react-date-picker__inputGroup__day {
    width: 20px !important;
}

.react-date-picker {
  height: auto;
  border-bottom: 1px solid gray;
}

.react-date-picker__wrapper {
  border: none !important;
}

.react-date-picker__wrapper button {
  margin-bottom: 0px !important;
}

.react-date-picker__button {
  padding: 4px 6px 0px 20px;
}

/*-------------page-style----------------------------*/

.schedule_mobile_view_title {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: auto;
  width: 100% !important;
}

.main_calendar_container {
  height: auto;
  width: 100%;
}

.main_calendar_container h5 {
  font-weight: 500;
  color: gray;
  margin-bottom: 10px;
}

.schedule_times_button {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  height: auto;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
}

.schedule_times_button button {
  margin: 8px;
}

.post-snippet .inner {
  padding: 0px !important;
}

/* .intervel_radio_container {
  height: auto;
  width: 100%;
  display: flex;
} */

/* .calendar_header_box div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: auto;
  width: 50%;
}

.calendar_header_box p {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 15px !important;
}

.calendar_header_box select {
  border: 1px solid lightgray;
  width: 300px;
  margin-bottom: 15px;
}

.calendar_header_box select:hover {
  border: 1px solid lightgray;
} */

/*-------------------------------------------doctor-schedule-appointment-style---------------------------------------------*/

.calendar_header_box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: auto;
  width: 100%;
  align-items: center;
}

.choose_location_header {
  display: flex;
  height: auto;
  width: 100%;
  align-items: center;
  margin-bottom: 20px;
}

.choose_location_header i {
  font-size: 22px;
  font-weight: bold;
}

.choose_location_header span {
  margin-left: 10px;
  font-size: 15px;
}

.location_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: auto;
  width: 100%;
  align-items: center;
  margin-bottom: 20px;
}

#l_input {
  display: flex;
  height: auto;
  width: 50%;
  align-items: center;
}

#l_input select {
  width: 100%;
  margin-bottom: 0px;
  background: #eee;
  -webkit-appearance: menulist !important;
  -moz-appearance: menulist!important;
  appearance: menulist!important;
}

#l_name {
  display: flex;
  height: auto;
  width: 50%;
  align-items: center;
  justify-content: center;
  padding-left: 5px;
  padding-right: 5px;
}

#l_name p {
  font-size: 16px;
}

.select_interval_header {
  display: flex;
  height: auto;
  width: 100%;
  align-items: center;
  margin-bottom: 20px;
}

.select_interval_header i {
  font-size: 22px;
  font-weight: bold;
}

.select_interval_header span {
  margin-left: 10px;
  font-size: 15px;
}

.interval_container {
  height: auto;
  width: 100%;
  display: flex;
}

.select_date_header {
  display: flex;
  height: auto;
  width: 100%;
  align-items: center;
  margin-bottom: 30px;
}

.select_date_header i {
  font-size: 22px;
  font-weight: bold;
}

.select_date_header span {
  margin-left: 10px;
  font-size: 15px;
}

.calendar_container {
  display: flex;
  /* flex-direction: row; */
  /* flex-wrap: wrap; */
  height: auto;
  width: 100%;
  margin-bottom: 22px;
}

.calendar_container label {
  font-size: 13px;
}

.select_time_header {
  display: flex;
  height: auto;
  width: 100%;
  align-items: center;
  margin-bottom: 25px;
}

.select_time_header i {
  font-size: 22px;
  font-weight: bold;
}

.select_time_header span {
  margin-left: 10px;
  font-size: 15px;
}

.time_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: auto;
  width: 100%;
  margin-bottom: 10px;
}

.time_container label {
  font-size: 13px;
}

.time_container select {
  background: #eee;
  margin-bottom: 0px;
  -webkit-appearance: menulist !important;
  -moz-appearance: menulist!important;
  appearance: menulist!important;
}


        .info-caln {
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            flex-wrap: wrap;
        }
        .info-caln li {
            margin-right: 10px;
            margin-bottom: 10px;
            font-size: 13px;
        }
        .sqr-block {
            width: 18px;
            height: 18px;
            border: 1px solid #ccc;
            border-radius: 4px;
            margin-right: 10px;
        }
        .wrap-flx{
            display: flex;
            align-items: center;
        }
        .bg-cl-one{
            background-color:#008f8e;
            border-color: #008f8e; 
        }
        .bg-cl-two{
           background-color:#1dc478;
           border-color: #1dc478; 
        }
        .bg-cl-three{
           background-color:#d3d3d3;
           border-color: #d3d3d3;  
        }
        .bg-cl-four{
           background-color:#fff; 
        }  

        .wraper-box-inf {
    margin: 10px;
    margin-bottom: 0;
}
.w-100 {
    width: 100%;
}  


/*-------------------------------------------doctor-schedule-appointment-media-query---------------------------------------------*/

@media (max-width: 768px) {
  .time_container .col-xs-12 {
    margin: 5px;
  }
  #l_input {
    width: 100%;
    margin-bottom: 24px;
  }
  #l_name {
    width: 100%;
  }
  #l_name p {
    font-size: 13px;
  }
  .time_container label {
    min-width: 30px;
  }
}

