.profile_notification_bar {
  height: 30px;
  width: 100%;
  background: #222222c4;
  position: absolute;
  top: 55px;
  z-index: 1;
  display: none;
}
.profile_notification_bar p {
  text-align: center;
  color: #fff;
  font-weight: bold;
  margin-bottom: 0px;
}
