@keyframes spinner-border {
    to { transform: rotate(360deg); }
 }
 .spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: .25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .75s linear infinite;
    animation: spinner-border .75s linear infinite;
 }



.intl-tel-input {
  display: table-cell;
}
.intl-tel-input .selected-flag {
  z-index: 4;
}
.intl-tel-input .country-list {
  z-index: 5;
}
.input-group .intl-tel-input .form-control {
  border-top-left-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 0;
}
.sl-arrow .form-control {
    -webkit-appearance: button !important;
    appearance: block !important;
    height: 50px;
    background: #e8f0fe;
    border: none;
    font-weight: 500;
    font-size: 13px;
    text-transform: unset !important;
    color: #000 !important;
}
.inpt-place input::placeholder {
    color: #000;
    font-size: 13px;
    font-weight: 500;
}

.plan-detail .form-control.form-input-error{
  border:1px solid red!important;
}