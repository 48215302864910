.doc_dashboard_section
{
    padding: 96px 50px;
}


@media (max-width: 768px) {
    .main-container section {
        padding: 30px 0px;
    }
    .main-container .feature.bordered {
        border: none !important;
    }
    .doc_dashboard_section
    {
        padding: 96px 0px !important;
    }
}