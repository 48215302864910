@keyframes reporting_spinner-border {
  to { transform: rotate(360deg); }
}
.reporting_spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: .25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: reporting_spinner-border .75s linear infinite;
  animation: reporting_spinner-border .75s linear infinite;
}


.filter_box
{
  height:auto;
  width:100%;
}
.filter_box .btn
{
  border-radius: 3px !important;
  margin-bottom: 0px !important;
  background-color: #47b475 !important;
  color:#fff !important;
  margin-top:10px;
  margin-left:10px;
}

.reporting_resp_table {
    width: 100%;
    display: table;
    z-index: 1;
    background: #f8f8f8;
    margin-top:15px;
  }

  .reporting-resp_table_header {
    display: table-header-group;
    background-color: #47b475;
    /* font-weight: bold; */
    font-size: 14px;
  }

  .reporting-table_header_cell {
    display: table-cell;
    padding: 10px;
    text-align: justify;
    border-bottom: 1px solid rgb(223, 190, 190);
    color: #fff;
  }

  .reporting-resp_table_body {
    display: table-row-group;
  }

  .reporting-resp_table_row {
    display: table-row;
  }

  .reporting-table_body_cell {
    display: table-cell;
    padding: 10px;
    text-align: justify;
  }


  .report_mobileView_title_container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }


  .report_mobileView_content tr {
    border-bottom: 1px solid #eee;
  }
  
  .report_mobileView_content tr:last-child {
    border: none;
  }
  .report_mobileView_content tr th {
    padding: 10px;
  }
  
  .report_mobileView_content tr td {
    padding: 10px;
    text-align: right;
  }

  .insurance_report_forMobile
  {
    display: none;
  }

.reporting_status p
{
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
}
.reporting_status h5
{
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: 500;
}





  @media (max-width: 768px) {
    .insurance_report_forMobile {
      display: block !important;
    }
    .reporting_resp_table {
      display: none;
    }
    .reporting_status
    {
      text-align: center;
    }
    .filter_box 
    {
      text-align: center;
    }
  }