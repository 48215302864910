

.navbarlianchor {
    color: #fff;
}



@media (max-width: 768px) {
    .doc_navbar {
        min-height: 0px !important;
    }
}

nav { position: relative; }
.dropdown-toggle {
  padding: .5em 1em;
  min-width:160px;
  border: 1px dotted #fff; 
  background: #fff;
  border-radius: .2em .2em 0 0;
}
ul.dropdown {
  position: absolute;
  top: 100%;
  margin-top: .5em;
  background: #777;
  min-width: 12em;
  padding: 0;
  border-radius: 0 0 .2em .2em;
}
ul.dropdown li { list-style-type: none; }
ul.dropdown a {
  text-decoration: none;
  padding: .5em 1em;
  display: block;
}

.dropdown.open .dropdown-menu { /* For Bootstrap 4, use .dropdown.show instead of .dropdown.open */
    max-height: 300px;
    opacity: 1;
}
 
@media screen and (min-width: 768px) {
  .dropdown:hover .dropdown-menu, .btn-group:hover .dropdown-menu{
        display: block;
    }
    .dropdown-menu{
        margin-top: 0;
    }
    .dropdown-toggle{
        margin-bottom: 2px;
    }
    .navbar .dropdown-toggle, .nav-tabs .dropdown-toggle{
        margin-bottom: 0;
    }
}