.search_dropdown_box {
  height: auto;
  width: 100%;
  background: #fff;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  padding: 0px 0px 0px 0px;
  overflow: auto;
  border: 1px solid lightgray;
  z-index: 100;
  position: absolute;
}

#doc_search_box {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: auto;
  width: 100%;
  padding-left: 10px;
  padding-top: 5px;
  border-top: 1px solid #e0e0e5;
  cursor: pointer;
  align-items: center;
  padding-left: 10px;
  padding-top: 5px;
  text-align: left;
  border-bottom: 1px solid #e0e0e5;
  cursor: pointer;
}

#doc_search_box:last-child {
  border: none;
}

#doc_search_box:hover {
  background: #f0f0f5;
  transition: 0.2s ease-in-out;
}

/*-----------------new style-of-docname-dropdown-----------------------*/

.searchDoc_speciality_group {
  height: auto;
  width: 100%;
}

#searchDoc_group_header {
  height: auto;
  width: 100%;
  padding-left: 10px;
  padding-bottom: 5px;
  padding-top: 5px;
  background-color: #f0f0f5;
  text-align: left;
  font-size: 11px;
  letter-spacing: 1px;
  color: #414146;
}

.searchDoc_speciality_group_data {
  height: auto;
  width: 100%;
  padding-left: 10px;
  padding-top: 5px;
  text-align: left;
  border-bottom: 1px solid #e0e0e5;
  cursor: pointer;
}

.searchDoc_speciality_group_data p {
  font-size: 13px;
  margin-bottom: 0px;
  color: #414146;
  font-family: Helvetica, Arial, sans-serif;
  letter-spacing: 0.5px;
}

.searchDoc_speciality_group_data:hover {
  background: #f0f0f5;
  transition: 0.2s ease-in-out;
}

.searchDoc_doctor_group {
  height: auto;
  width: 100%;
}


.search_zip_searchContainer {
  height: auto;
  width: 100%;
  background: #fff;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  padding: 0px 0px 0px 0px;
  overflow: auto;
  border: 1px solid lightgray;
  z-index: 100;
  position: absolute;
}

.search_zip_items {
  height: auto;
  width: 100%;
  border-bottom: 1px solid #e0e0e5;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #333;
  text-align: center;
}

.search_zip_items:last-child {
  border: none;
}

.search_zip_items:hover {
  background-color: #e0e0e5;
}


.zipfilterdropDown
{
  height:350px;
  width: auto;
  min-width: 200px;
  position: absolute;
  top:35px;
  border-radius: 3px;
  padding:10px 0px 10px 0px;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  z-index: 100;
  left: 0px;
  border:1px solid lightgray;
  background: #fff;
  overflow: auto;
}
.zipfilterdropDown li
{
 list-style-type:none ;
}
.backdrop
{
  height:100%;
  width:100%;
  position: fixed;
  top:0px;
  z-index: 90;
  display: none;
}


@media (max-width: 768px) {
  .filter_option_mobile {
    width: 100%;
    justify-content: center;
  }
  .shortedBy_button {
    width: 100%;
    margin-top: 10px;
    justify-content: center;
  }
  .zipfilterdropDown
  {
    height:270px;
    width: auto;
    min-width: 160px;
    overflow: scroll;
  }
}