.review_inner_container {
  height: auto;
  width: 100%;
  /* background: red; */
  padding-bottom: 1em;
  border-bottom: 1px solid #f3f3f3;
  /* padding: 10px; */
}
#name_date_rating_box {
  display: flex;
  flex-wrap: wrap;
  height: auto;
  width: 100%;
}
#date {
  display: flex;
  height: auto;
  width: 33.3%;
  /* background: gray; */
  /* padding: 10px; */
  /* justify-content: center; */
  align-items: center;
}
#patient_name {
  display: flex;
  height: auto;
  width: 33.3%;
  /* background: orange; */
  align-items: center;
}
#rating {
  display: flex;
  height: auto;
  width: 33.3%;
  /* background: green; */
  align-items: center;
}
#dec {
  height: auto;
  width: 100%;
  padding-top: 5px;
}

.doc_review_for_web {
  width: 100%;
  display: table;
}

/*---------------------------for-mobile--------------------*/
.doc_review_for_mobile
{
  display: none;
}

.doc_review_title_box {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.doc_review_mobile_content tr {
  border-bottom: 1px solid #eee;
}

.doc_review_mobile_content tr:last-child {
  border: none;
}
.doc_review_mobile_content tr th {
  /* background: red; */
  padding: 10px;
}

.doc_review_mobile_content tr td {
  padding: 10px;
  text-align: right;
}


.doc_review_resp-table-header {
  display: table-header-group;
  background-color: #47b475;
  font-weight: bold;
  font-size: 14px;
}

.doc_review_table-header-cell {
  display: table-cell;
  padding: 10px;
  text-align: justify;
  border-bottom: 1px solid lightgray;
  color: #fff;
}
.doc_reivew_resp-table-body {
  display: table-row-group;
}

.doc_review_resp-table-row {
  display: table-row;
}

.doc_reivew_table-body-cell {
  display: table-cell;
  padding: 10px;
  text-align: justify;
}



/*-------------------------for-mobile-------------------------*/

@media (max-width: 768px) {
  .doc_review_mobile_content .dv-star-rating {
    justify-content: flex-end !important;
  }
  .doc_review_for_mobile {
    display: block !important;
  }
  .doc_review_for_web {
    display: none;
  }
  .dv-star-rating {
    display: inline-block !important;
  }
}