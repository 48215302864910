.appointment_table_box {
  position: relative;
  height: auto;
  width: 100%;
  /* background: pink; */
}

.table > thead > tr > th {
  vertical-align: top !important;
 
}

/* .appointment_table_box table {
  width: 100%;
}
.appointment_table_box tr th {
  padding: 15px;
  background: #47b475;
  color: #fff;
  font-weight: bold;
  font-size: 15px;
}
.appointment_table_box tr {
  border-bottom: 1px solid #ccc;
}
.appointment_table_box tr:last-child {
  border: none;
}
.appointment_table_box tr td {
  padding: 18px;
  font-weight: bold;
} */

.select_new_date_popup_box {
  height: auto;
  width: 250px;
  background: #fff;
  position: absolute;
  border-radius: 4px;
  padding: 10px;
  box-shadow: 1px 1px 5px 1px lightgray;
  z-index: 100;
}

.select_new_date_popup_box h5 {
  font-weight: bold;
  color: gray;
}

.select_new_date_popup_box select {
  height: 45px;
  width: 100%;
  border-radius: 4px;
  outline: none;
  border: 1px solid lightgray;
  padding-left: 5px;
}

.select_new_date_popup_box button {
  margin-bottom: 10px;
  background: #47b475;
  color: #fff;
  line-height: 10px;
}

.backdrop {
  height: 100%;
  width: 100%;
  /* background:; */
  position: fixed;
  top: 0px;
  z-index: 80;
  left: 0px;
}

.recent_appointments_container {
  height: auto;
  width: 100%;
  background: #f8f8f8;
}

.total_appointments_container {
  height: auto;
  width: 100%;
  /* background: orange; */
  padding-top: 10px;
  padding-bottom: 10px;
  background: #f8f8f8;
  margin-top: 10px;
}

.total_appointments_container select {
  margin-bottom: 0px;
}

.popup_container {
  height: auto;
  width: 350px;
  background: #47b475;
  margin: 0 auto;
  transition: 1s ease-in-out;
  border-radius: 3px;
  padding: 15px 0px 0px 0px;
  position: absolute;
  /* left: 25%; */
  right: -100%;
  top: 25%;
  /* bottom: 25%; */
  box-shadow: 0px 1px 6px 1px #8a8787;
  z-index: 100;
}

.user_profile_container {
  display: flex;
  flex-wrap: wrap;
  height: auto;
  width: 100%;
  /* background: green; */
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: center;
  align-content: center;
  border-bottom: 1px solid #bbbbbb;
}

#name_box {
  height: auto;
  width: 100%;
  /* background: orange; */
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  color: #fff;
}

.appointment_details {
  height: auto;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  /* background: green; */
  border-bottom: 1px solid #bbbbbb;
  text-align: center;
}

.appointment_details p {
  margin-bottom: 2px;
  color: #fff;
  font-size: 14px;
}

.popup_action_container {
  display: flex;
  flex-direction: row;
  height: auto;
  width: 100%;
  /* background: green; */
  padding-top: 15px;
  align-items: center;
  justify-content: space-around;
}

.popup_action_container button {
  line-height: 0px;
  background: #36985f;
  border: 1px solid #36985f;
  outline-color: #36985f;
}

.popup_action_container button:hover {
  background: #36985f;
}

.recent_today_upcoming_appointment_container {
  display: flex;
  flex-wrap: wrap;
  height: auto;
  width: 100%;
  /* background: green; */
}

#appintment_row {
  /* display: flex; */
  height: auto;
  width: 100%;
  /* background: red; */
  z-index: 0;
}

#appintment_row table {
  margin-bottom: 0px !important;
}

#appintment_row tr td {
  padding-left: 15px;
  padding-right: 15px;
  background: #f8f8f8;
}

#appintment_row td select {
  margin-bottom: 0px;
}

#appintment_row td button {
  margin-bottom: 0px;
  height: 25px;
  background: #36985f;
  line-height: 5px;
  font-weight: normal;
  letter-spacing: 1px;
  padding: 5px !important;
  font-weight: 400 !important;
  outline: none;
}

#appointment_notification_row {
  display: table-row;
  height: auto;
  width: 100%;
  background: #36985f;
  transition: 1s ease-in-out;
  /* margin-top: -40px; */
  z-index: -1;
}

#appointment_notification_row p {
  color: #fff;
  font-weight: bold;  
  text-align: center;
}

.upcoming_appointments_container {
  height: auto;
  width: 100%;
  /* background: orange; */
  padding-top: 10px;
  padding-bottom: 10px;
  background: #f8f8f8;
  margin-top: 10px;
}

.upcoming_appointments_container select {
  margin-bottom: 0px;
}

.recent_action_box {
  display: flex;
  height: auto;
  /* background: red; */
  align-items: center;
  justify-content: center;
}

.recent_action_box button {
  margin: 2px;
}

/*------------------------responsive-table-css-------------------------------------*/

.doc_appointment-resp_table {
  width: 100%;
  display: table;
  z-index: 1;
  /* background: #f8f8f8; */
}


.doc_appointment-resp_table h5
{
  font-weight: bold;
  color:#fff;
  font-size: 16px;
  margin-bottom: 0px;
}
.doc_appointment-resp_table p
{
  font-weight: 600;
  font-size:  12px;
}

.doc_appointment-resp_table tbody td h5
{
  color:#333;
  font-size: 12px;
}

.doc_appointment-resp_table_header {
  display: table-header-group;
  background-color: #47b475;
  font-weight: bold;
  font-size: 14px;
}

.doc_appointment-table_header_cell {
  display: table-cell;
  padding: 10px;
  text-align: justify;
  border-bottom: 1px solid rgb(223, 190, 190);
  color: #fff;
}

.doc_appointment-resp_table_body {
  display: table-row-group;
}

.doc_appointment-resp_table_row {
  display: table-row;
}

.doc_appointment-resp_table_row button {
  margin-bottom: 0px !important;
  outline: none;
}

.doc_appointment-table_body_cell {
  display: table-cell;
  padding-top:5px;
  padding-bottom: 5px;
  text-align: justify;
}

/*-------------------------for recent-appoitnemnt-mobile-view------------------------*/

.recentAppointment_mobileview_title_box {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: nowrap !important;
}

.recentAppointmentmobile_content_table tr {
  border-bottom: 1px solid #eee;
}

.recentAppointmentmobile_content_table tr:last-child {
  border: none;
}

.recentAppointmentmobile_content_table tr th {
  /* background: red; */
  padding: 10px;
}

.recentAppointmentmobile_content_table tr td {
  padding: 10px;
  text-align: right;
}

.recentAppointmentmobile_content_table button {
  padding: 1.5px !important;
}

/*-------------------------for upcomint and past -appoitnemnt-mobile-view------------------------*/

.upcomingPastAppointment_mobileview_title_box {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.upcomingPastAppointmentmobile_content_table tr {
  border-bottom: 1px solid #eee;
}

.upcomingPastAppointmentmobile_content_table tr:last-child {
  border: none;
}

.upcomingPastAppointmentmobile_content_table tr th {
  /* background: red; */
  padding: 10px;
}

.upcomingPastAppointmentmobile_content_table tr td {
  padding: 10px;
  text-align: right;
}

.apointTable_mobile_view {
  display: none;
}

.pagination>li:first-child>a, .pagination>li:first-child>span {
  width: auto;
  padding-left: 8px;
  padding-right: 8px;
}

.pagination>li:last-child>a, .pagination>li:last-child>span {
  width: auto;
  padding-left: 8px;
  padding-right: 8px;
}

/*-------------------------media-query-for-apoint-table-mobile and web-----------------------------*/

@media (max-width: 768px) {
  .apointTable_mobile_view {
    display: block !important;
  }
  .doc_appointment-resp_table {
    display: none;
  }
}