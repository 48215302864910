/****** PLACE YOUR CUSTOM STYLES HERE ******/


/* Media query */

/* @media all and (max-width: 1224px) {
  .col-md-push-1 {
    left: 0;
  }
}

@media all and (max-width: 1224px) {
  .module.widget-handle {
    padding: 0 16px;
  }
}



@media all and (max-width: 1824px) {
  .col-md-push-1 {
    left: 0;
  }
}

@media all and (max-width: 1824px) {
  .module.widget-handle {
    padding: 0 16px;
  }
}
 */

.customsec .container {
  width: 100% !important;
}

/* From theme */


form.fourths input,
select {
  width: 20%;
  float: left;
  margin-left: 0.5%;
  margin-bottom: 16px;
}

form.fourths button {
  width: 16%;
  float: left;
  margin-left: 0.5%;
  margin-bottom: 16px;
}
.post-snippet form {

    height: auto;
    overflow: hidden;

}
.post-snippet select.col-md-12 {
    margin-left: 0px !important;
    width: 100%;
}

form.halves p,
form.thirds p,
form.fourths p,
form.halves span,
form.thirds span,
form.fourths span {
  clear: both;
}

@media all and (max-width: 767px) {

  form.fourths input,
  form.thirds input,
  form.halves input,
  form.fourths select,
  form.thirds select,
  form.halves select,
  form.fourths button,
  form.thirds button,
  form.halves button {
    width: 100%;
    margin-bottom: 8px;
    float: left;
    margin-left: 0;
    text-transform: capitalize;
  }
}

.feature.bordered {
  padding: 16px !important;
}

/* custom colors */

.bg-primary-2 {
  background-color: #4527a0;
}

.bg-primary-3 {
  background-color: #00bcd4;
}

.bg-primary-4 {
  background-color: #ffd740;
}

.bg-primary-5 {
  background-color: #ffd740;
}

.mr8 {
  margin-right: 8px;
}

.ml8 {
  margin-left: 8px;
}

.ml16 {
  margin-left: 16px;
}
/* 
.card-imges {
  display: flex;
}

.card-imges li {
  max-width: 64px;
  padding: 4px 8px;
}

.card-imges li img {
  width: 100%;
}

.active.card-imges li:not(.active) {
  filter: grayscale(1);
}

.active.card-imges li.active:after {
  position: absolute;
  content: "";
  border: 1px solid #337ab7;
  width: 100%;
  height: 40px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
}

.active.card-imges li {
  position: relative;
} */

.clear:after {content: "";clear: both;display: table;}

/* .nav > li > a.active {
  background-color: #eee;
  color: #23527c;
} */

.nav-link{
  cursor: pointer;
}
.width-100{
  width: 100%!important;
}

.subscription li.current-active a{
  border: 1px solid;
}
.mb-0{
  margin-bottom: 0;
}

.pt-2 {
  padding-top: 2rem;
}

.fix-mid-right {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 0 20px;
  font-weight: 700;
  width: 80px;
  color: #191d67;
  display: block;
}

.position-relative {
  position: relative;
}
.fix-mid-right img {
  width: 100%;
}


/* slide subscription tab */


.animate-tab .tab-pane .tab-pane-inner {
  transition: 0.5s all;
  transform: translate3d(100%, 0px, 0px);
}

.animate-tab .tab-pane {
  overflow: hidden;
}

.animate-tab .tab-pane.active .tab-pane-inner {
  transform: translate3d(0%, 0px, 0px);
}

.animate-tab .tab-content > .tab-pane {
  display: block!important;
  visibility: hidden;
  height: 0;
}

.animate-tab .tab-content > .tab-pane.active {
  visibility: visible;
  height: auto;
}

.animate-tab .tab-pane.active-filled .tab-pane-inner {
  transform: translate3d(-100%, 0px, 0px);
}

/* ******************* */

.full-screen.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(0 0 0 / 20%);
  color: #388f5e;
}

.full-screen.loader .spinner-border{
  width: 4rem;
  height: 4rem;
}
.section-screen.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(0 0 0 / 50%);
}
.section-screen.loader .bubble {
  max-width: 60px; 
}
.mb-4 {
  margin-bottom: 4rem;
}

.cu-recharts-1 .recharts-default-legend > li {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 0!important;
  text-align: left;
}

.cu-recharts-2 .recharts-layer.recharts-label-list .recharts-text.recharts-label tspan {
  fill: #ffffff;
}
.cu-recharts-2 .recharts-layer.recharts-bar-rectangle {
  opacity: 0.8;
}
@media only screen and (max-width:767px){
  .cu-recharts-1 .recharts-legend-wrapper {
    position: relative!important;
}

.cu-recharts-1 {
    margin-bottom: 110px;
}
}
.cu-recharts-1 .recharts-default-legend > li .recharts-legend-item-text {
  color: rgb(102, 102, 102)!important;
  font-weight: 500;
}

@media only screen and (max-width:1150px) and (min-width:992px){
  .cu-recharts-1 .recharts-legend-wrapper {
   bottom:-68px!important;
}


}

.no-data {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.react-confirm-alert-overlay{
  background-color: rgb(0 0 0 / 50%)!important;
}

@media only screen and (max-width:768px){
  .cu-389 button {
    padding: 0 10px!important;
    margin: 5px!important;
    min-width: 108px;
    min-height: 30px;
}
.custom-css-header-desktop .logo.logo-dark{
  margin-left: 15px!important;
}
}
@media only screen and (min-width:768px){
.custom-css-header-desktop {
  padding-left: 50px;
  padding-right: 50px;
  width: 100%;
}
}
.post-snippet .container.transition, .post-snippet .container.transition .container {
  max-width: 1170px!important;
  width: 100%!important;
}

.br-0{
  border-right: 0!important;
}

.bl-0{
  border-left: 0!important;
}


.op-chat .dropdown > button {
    margin: 0;
    padding: 1px 5px;
    background: transparent;
    height: auto;
    outline: none;
}

.op-chat {
    position: absolute;
    top: 0;
    left: 5px;
}

.op-chat .dropdown-menu.cs_drop_title {
    min-width: auto;
}

.op-chat .dropdown-menu.cs_drop_title button {
    margin-bottom: 1px;
    outline: none!important;
    box-shadow: none!important;
    border: 0;
    padding: 0 10px;
    font-size: 12px;
    font-weight: 600;
}

.inner_chat_info .title_text img {width: 15px!important;height: auto!important;margin: 0 2px!important;}

.hoverAssignStaff{
    background-color:#fff !important;
    font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
    border: 1px solid #47b475;
    /* padding: 0 16px; */
    height: 30px;
    /* min-width: 100px; */
    line-height: 26px;
    font-size: 12px;
    font-weight: 700;
    text-transform: capitalize;
    letter-spacing: 1px;
    border-radius: 0;
    color: #47b475;
    text-align: center;
    border-radius: 10px;
    width: 100px;
}

.hoverAssignStaff:hover{
  background-color: #47b475 !important;
  color:#fff !important;
}

.alertCss{
  opacity: 1;
  margin: 0;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.loop_box.active {
  background-color: #2c2d35;
}

.u_img.online > div, .chat_user_icon.online {
  position: relative;
}

.u_img.online > div:after, .chat_user_icon.online > div:after {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 3px solid #5ab476;
  content:"";
  top:0;
  left:0;
  z-index:99;
border-radius:50%
  
}

.loop_box.unread * {
  font-weight: 600;
}

.top_header .chat_user_icon {
  height: auto;
  width: auto;
}